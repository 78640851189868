import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

const Scholarships = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Scholarships</title>
    </Helmet>
    <section class="hero is-halfheight hero-banner" id="scholarships-hero">
          <div class="hero-body">
          </div>
    </section>      
    <main>
        <h1>Scholarships</h1>
        <p>The KEEP Foundation Scholarship in partnership with the Race for Education is available to Kentucky students pursuing a field of study within the equine, animal science, or agriculture industry and/or whose family is employed in the equine, animal science or agriculture industry.</p>
        <p>Application and supporting materials must be submitted by MAY 1, 2023 (Applications open January 1, 2023). Up to $5,000 per year.</p>
        <p>Supporting materials include:</p>
        <ul>
          <li>Current Official Transcript (seal can be broken)</li>
          <li>Resume</li>
          <li>Free Application For Federal Student Aid (FAFSA)</li>
          <li>Two signed letters of recommendation.</li>
          <li>Award letter(s) for other scholarship, if applicable</li>
        </ul>
        <p>Additional Race for Education scholarships can be found at <a href="http://raceforeducation.org/" target="_blank">http://raceforeducation.org/</a>.</p>

    </main>
    </Layout>
  )
}

export default Scholarships

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`